<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
                active
              >
                Difusores oficiales del PREP 2021
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>

          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Conoce el PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Difusores Oficiales del PREP 2021
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Difusores Oficiales del PREP 2021
          </h2>

          <p>
            Son los medios de comunicación (prensa, radio, televisión e internet) e
            instituciones académicas que difunden los datos publicados en el PREP
            a través de sus portales en Internet.
          </p>

          <div class="row align-items-center my-5">
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                ¿Qué hacen los Difusores?
              </h4>

              <ul>
                <li>
                  <b>Replican</b> los resultados sin manipularlos, modificarlos, ni interpretarlos.
                </li>
                <li>
                  <b>Aseguran</b> que la información difundida en su sitio web es originada
                  y proporcionada por el INE.
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/difusor-1.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <div class="row align-items-center my-5">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/difusor-2.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <h4>
                ¿Cómo consultar el PREP a través de los Difusores?
              </h4>

              <ul>
                <li>
                  Ingresa a la página de Difusores PREP 2021.
                </li>
                <li>
                  Selecciona el difusor de tu interés,
                  te redirigirá a su página para visualizar el PREP.
                </li>
              </ul>
            </div>
          </div>

          <h4>
            Elementos de la página de Difusores
          </h4>

          <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/difusor-3.svg" alt="" class="img-fluid d-block mx-auto mb-4">

          <div class="row align-items-center my-5">
            <div class="col-12 col-lg-6 mb-3">
              <p>
                <b>
                  <span class="mr-3">1</span>
                  Logo de Difusores PREP 2021,
                </b>
                consulta la página del difusor de tu preferencia.
              </p>

              <p>
                <b>
                  <span class="mr-3">2</span>
                  Convocatoria,
                </b>
                consulta los requerimientos que atendieron los difusores para el PREP 2021.
              </p>

              <p>
                <b>
                  <span class="mr-3">3</span>
                  Centro de Ayuda,
                </b>
                consulta la explicación sobre aspectos relacionados
                con el proceso electoral y el PREP.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <p>
                <b>
                  <span class="mr-3">4</span>
                  Tamaño de letra,
                </b>
                aumenta o disminuye la fuente en la pagina.
              </p>

              <p>
                <b>
                  <span class="mr-3">5</span>
                  Modo Claro y Oscuro,
                </b>
                selecciona el modo en el que desees visualizar el sitio.
              </p>

              <p>
                <b>
                  <span class="mr-3">6</span>
                  Botón Actualizar,
                </b>
                refresca la página para visualizar los difusores.
              </p>
            </div>
          </div>

          <p class="mb-5">
            Las ilustraciones mostradas en la infografía son representativas.
          </p>

          <section class="text-center">
            <p>
              <b>
                Referencias:
              </b>
            </p>

            <p>
              · Artículo 353 del Reglamento de Elecciones. <br />
              · Numeral 4, inciso e y numeral 37 del Proceso Técnico
              Operativo del PREP, Proceso Electoral Federal 2020-2021.
            </p>
          </section>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Prep/Conoce_el_PREP_Infografias/Difusores_Oficiales_del_PREP_2021.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}
</style>
